import * as React from 'react'
import styled from '@emotion/styled'
import { transparentize } from 'polished'
import { Link } from 'gatsby'
import { heights, dimensions, colors } from '../styles/variables'
import Container from './Container'

const StyledHeader = styled.header`
  padding: 0 ${dimensions.containerPadding}rem;
  background-color: white;
  color: ${transparentize(0.5, colors.white)};
`
const HeaderTitleBg = styled.div`
  background-color: #355724;
  text-align: center;
  a {
    color white;
  }
`
const Footer: React.FC<{}> = () => {
  return (
    <StyledHeader>
      <HeaderTitleBg>
        <Link to="/impressum">Impressum</Link>
      </HeaderTitleBg>
    </StyledHeader>
  )
}

export default Footer
