import * as React from 'react'
import styled from '@emotion/styled'
import Container from '../Container'
import { useStaticQuery, graphql, Link } from 'gatsby'

const StyledUl = styled.ul``
const StyledLi = styled.li`
  padding-right: 30px;
  white-space: nowrap;
  list-style-type: none;
  float: left;
`
export default function ShopMenu() {
  const query = useStaticQuery(graphql`
    query ShopMenuQuery {
      allCategoriesJson {
        edges {
          node {
            slug
            name
          }
        }
      }
    }
  `)

  const categories = query.allCategoriesJson.edges.map(i => i.node)

  return (
    <Container>
      <StyledUl>
        {categories.map(i => (
          <StyledLi>
            <Link to={`/shop/kategorie/${i.slug}`}>{i.name}</Link>
          </StyledLi>
        ))}
      </StyledUl>
    </Container>
  )
}
