import * as React from 'react'
import styled from '@emotion/styled'
import { transparentize } from 'polished'
import { Link } from 'gatsby'
import { heights, dimensions, colors } from '../styles/variables'
import Container from './Container'
import CartContext from './shop/CartContext'

const StyledHeader = styled.header`
  padding: 0 ${dimensions.containerPadding}rem;
  background-color: white;
  color: ${transparentize(0.5, colors.white)};
`

const HeaderInner = styled.div`
  align-items: center;
  background-image: url('/logo.png');
  background-repeat: no-repeat;
  background-size: 350px;
  background-position: top center;
  padding-top: 150px;
`

const HomepageLink = styled(Link)`
  color: ${colors.white};
  font-size: 1.5rem;
  font-weight: 600;

  &:hover,
  &:focus {
    text-decoration: none;
  }
`

const HeaderTitle = styled.h1`
  padding-left: 20px;
  padding-bottom: 10px;
`
const HeaderTitleBg = styled.div`
  background-color: #355724;
`
const HeaderTitleOuter = styled(Container)`
  display: flex;
`

const ShopLinkContainer = styled.div`
  padding-top: 20px;
  padding-left: 20px;
`
const ShopLink = styled(Link)`
  font-weight: bold;
  color: ${colors.white};
  &:hover,
  &:focus {
    text-decoration: none;
  }
`
const CartContainer = styled.div`
  position: absolute;
  top: 10px;
`
const SocialLinks = styled.a`
  position: absolute;
  right: 10px;
  top: 10px;
`
interface HeaderProps {
  title: string
}

const Header: React.FC<HeaderProps> = ({ title }) => {
  const cartContext = React.useContext(CartContext)
  return (
    <StyledHeader>
      <Container>
        <CartContainer>
          {cartContext.cart.length > 0 && <Link to="/warenkorb"> {cartContext.cart.length} Produkte im Warenkorb</Link>}
        </CartContainer>
        <SocialLinks>
          <a href="https://www.facebook.com/samshofbauer/">
            <img src="/facebook.svg" width="40" height="40" />
          </a>
          <a href="https://www.instagram.com/samshofbauer/">
            <img src="/instagram.svg" width="40" height="40" />
          </a>
        </SocialLinks>
      </Container>
      <HeaderInner>
        <HeaderTitleBg>
          <HeaderTitleOuter>
            <HeaderTitle>
              <HomepageLink to="/">Bio Hofkäserei Sams Hofbauer</HomepageLink>
            </HeaderTitle>
            <ShopLinkContainer>
              <ShopLink to="/shop">NEU: Shop</ShopLink>
            </ShopLinkContainer>
          </HeaderTitleOuter>
        </HeaderTitleBg>
      </HeaderInner>
    </StyledHeader>
  )
}

export default Header
